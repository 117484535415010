.hello{
    justify-content: center;
    display: flex;
    align-items: center;
}

.HRStyling{
    margin-bottom: 0px;
}

.helloTitleBold{
    font-family:monospace
}

.helloDivider{
    margin-top: 2rem;
    margin-bottom: 0px;
    padding: 0px;
}

.restrictWidth{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 0px;

}

.carouselContainer{
    padding: 4px;
    border-radius: 30px;
    width: fit-content;
    margin-top: 0px;
}

.cardSpacing{
    margin-top: 8px;
    text-decoration: none;
}

.carouselSelf{
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
}

.carouselItem{
    max-height: 70dvh;
}

.carouselImage{
    border-radius: 10px;
    max-width: 100%;
    max-height: 50vh;
    object-fit: cover;
    object-position: 40% bottom;
    min-width: 40dvh;
}

.carouselImageHTH{
    border-radius: 10px;
    max-width: 100%;
    max-height: 50vh;
    object-fit: cover;
    object-position: 62% bottom;
    min-width: 40dvh;
}

.carouselCaption{
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 20px;
    padding: 24px;
}

.cardLink{
    text-decoration: none;
    color: black;
}

